import dayjs from "dayjs";
import Api from "../../../services/Api";
import {Button, Card, message, Popconfirm, Table} from "antd";
import {useEffect, useState} from "react";
import Column from "antd/es/table/Column";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import flexCurrency from "../../../utils/FlexCurrency";

const SalesListPage  = ({refreshData}) => {
    const [data, setData] = useState([])
    const [headerHide, setHeaderHide] = useState(false)
    let user = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {
        getData()
    }, [refreshData]);

    const getData = () => {
        const query = {
            "sort": [
                {
                    "field": "createdDate",
                    "dir": "desc"
                }
            ],
            "filter": {
                "field": "CreatedDate.Date",
                "operator": "eq",
                "value": `${dayjs(new Date()).format("YYYY-MM-DD")}`,
                "logic": "and",
                "filters": [
                    {
                        "field": "Buyer",
                        "operator": "eq",
                        "value": user?.email,
                        "logic": "and"
                    }
                ]
            }
        }


        Api.post("/api/Buys/GetList/ByDynamic?PageIndex=0&PageSize=10000", query).then(res => {
            res.data.items.map((item) => {
                let total = 0;
                let detail= "";
                item.buyDetails.map((buy) => {
                    total += buy.recipeRowTotalPrice
                    detail += buy.recipeName + " " + buy.count + " Kişi, "
                })
                item.priceTotal = parseInt(total.toFixed(2))
                item.detail = detail
            })

            setData(res.data.items)

        }).catch(err => {
            console.log(err)
            message.error("Gerçekleşen satışlar getirilemedi")
        })
    }

    const hideHeaderToggle = () => {
        setHeaderHide(!headerHide)
    }

    const deleteBuy = (id) => {


        Api.delete(`/api/Buys/${id}`).then(res => {
            message.success("Satış iptal edildi")
            getData()
        }).catch(err => {
            console.log(err)
            message.error("Satış iptal edilemedi")
        })
    }

    //kredi kartı satışlarını bulmam lazım
    const creditCartTotal = data.filter((item) => item.buyType === "KREDİ KARTI").reduce((total, item) => total + item.priceTotal, 0)
    const cashTotal = data.filter((item) => item.buyType === "NAKİT").reduce((total, item) => total + item.priceTotal, 0)

    return(
        <Card size={"small"} title={"Satış Listesi"}
              extra={<Button icon={<FontAwesomeIcon icon={headerHide?faEye:faEyeSlash}></FontAwesomeIcon>} size={"small"} onClick={() => hideHeaderToggle()}>{headerHide ? "TUTARLARI GİZLE" : "TUTARLARI AÇ"}</Button>}
        >
            <Table
                rowKey={"id"}
                dataSource={data}
                size={"small"}
                pagination={false}
                showHeader={headerHide}
            >

                <Column width={50} dataIndex={"id"} render={(_,record) => {
                    return (
                        <Popconfirm
                            title="Satış İptali"
                            description="Satışı iptal etmek istediğinize emin misiniz?"
                            onConfirm={() => deleteBuy(record.id)}
                            onCancel={() => {}}
                            okText="Evet, iptal et"
                            cancelText="Vazgeç"
                        >
                            <Button size={"small"} type={"primary"} style={{fontSize:10}} danger={true}>İptal</Button>
                        </Popconfirm>
                    )
                }}></Column>
                <Column width={175} title={"Zaman"} dataIndex={"createdDate"} render={(_,record) => {
                    return dayjs(record.createdDate).format("DD.MM.YYYY HH:ss")
                }}></Column>
                <Column width={120} title={"Satış Tipi"}  align={"center"} dataIndex={"buyType"}></Column>
                <Column width={200} title={"İşlem Yapan"} align={"center"} dataIndex={"buyer"}></Column>
                <Column render={priceTotal => (flexCurrency(priceTotal) + " TL")} title={`TOPLAM: ${flexCurrency(data.reduce((total, item) => total + item.priceTotal, 0))} TL | K.KART: ${flexCurrency(creditCartTotal)} TL | NAKİT: ${flexCurrency(cashTotal)} TL`} align={"right"} dataIndex={"priceTotal"}></Column>
                <Column title={"Satış Detay"} align={"left"} dataIndex={"detail"}></Column>
            </Table>
        </Card>

    )
}

export default SalesListPage