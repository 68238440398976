import React from 'react';
import { createContext, useEffect, useState } from 'react';
import {message} from "antd";

const SerialPortContext = createContext();

const SerialPortContextProvider = ({ children }) => {
    const [ports, setPorts] = useState([]);
    const [data, setData] = useState('');
    const [sendData, setSendData] = useState('');
    const [selectedPort, setSelectedPort] = useState(null);

    useEffect(() => {
        // Seri portları listele
        try {
            window.electron
                .invoke('list-serial-ports')
                .then((response) => {
                    if (response.error) {
                        setData(response.error);
                    } else {
                        setPorts(response);
                    }
                })
                .catch((error) => setData('Error fetching serial ports'));
        } catch (error) {
            // message.error("Seri portlar listelenirken hata oluştu");
            // console.log(error);
        }

    }, []);

    useEffect(() => {
        try {
            const removeListener = window.electron.on('serial-port-data', (event, serialData) => {
                // setData(serialData); // Seri porttan gelen veriyi ekrana yazdır
                // setData(...data, serialData);
                setData(prevData => prevData + '\n' + serialData);// Append new data to the existing array
            });

            // Cleanup: bileşen unmount olduğunda dinleyiciyi kaldır
            return () => {
                removeListener();
            };
        } catch (error) {
            // console.log(error);
            // message.error("Seri porttan veri alınırken hata oluştu");
        }

    }, []);

    const connectToPort = (portPath) => {
        try {
            window.electron
                .invoke('connect-serial-port', portPath)
                .then((response) => {
                    if (response.error) {
                        setData(response.error);
                    } else {
                        setSelectedPort(portPath); // Store selected port path
                        console.log(response.success);
                    }
                })
                .catch((error) => setData('Error connecting to serial port'));
        } catch (error){

        }

    };

    const sendSerialData = (e) => {
        console.log(selectedPort, e);
        window.electron
            .invoke('send-serial-data', { port: selectedPort, data: e })
            .then((response) => {
                if (response.error) {
                    setData(response.error);
                    console.log(response.error)
                } else {
                    console.log('Data sent successfully');
                }
            })
            .catch((error) => setData('Error sending data'));
    };

    const disconnectPort = () => {
        window.electron
            .invoke('disconnect-serial-port')
            .then((response) => {
                if (response.error) {
                    setData(response.error);
                } else {
                    setSelectedPort(null); // Clear selected port
                    console.log(response.success);
                }
            })
            .catch((error) => setData('Error disconnecting from serial port'));
    }

    return (
        <SerialPortContext.Provider value={{
            ports,
            data,
            sendData,
            selectedPort,
            setSendData,
            connectToPort,
            sendSerialData,
            disconnectPort,
            setData
        }}>
            {children}
        </SerialPortContext.Provider>
    );
};

export {SerialPortContextProvider, SerialPortContext};