import {createBrowserRouter, createRoutesFromElements, defer, Route} from "react-router-dom";
import ProtectedLayout from "./layout/ProtectedLayout";
import DashboardPage from "./pages/home/DashboardPage";
import LoginPage from "./pages/auth/LoginPage";
import AuthLayout from "./layout/AuthLayout";
import "../src/styles/Style.sass"
import RecipeListPage from "./pages/recipe/RecipeListPage";
import RecipeAddPage from "./pages/recipe/RecipeAddPage";
import RecipeDetail from "./pages/recipe/RecipeDetail";
import UsersListPage from "./pages/users/UsersListPage";
import UserAddPage from "./pages/users/UserAddPage";
import UserDetailPage from "./pages/users/UserDetailPage";
import KasaGunlukRaporPage from "./pages/reports/KasaGunlukRaporPage";
import KasaDetayRaporPage from "./pages/reports/KasaDetayRaporPage";
import KasaAylikDokumRaporPage from "./pages/reports/KasaAylikDokumRaporPage";

const getUserData = () =>
    new Promise((resolve) =>
        setTimeout(() => {
          const user = window.localStorage.getItem("user");
          resolve(user);
        }, 1000)
    );

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<AuthLayout />} loader={() => defer({ userPromise: getUserData() })}>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<ProtectedLayout />}>
                <Route path="/" element={<DashboardPage />} />
            </Route>
            <Route path="/dashboard" element={<ProtectedLayout />}>
                <Route path="/dashboard" element={<DashboardPage />} />
            </Route>

            <Route path="/recipe" element={<ProtectedLayout />}>
                <Route path="/recipe" element={<RecipeListPage />} />
                <Route path="/recipe/add" element={<RecipeAddPage />} />
                <Route path="/recipe/detail" element={<RecipeDetail />} />
            </Route>

            <Route path="/user" element={<ProtectedLayout />}>
                <Route path="/user" element={<UsersListPage />} />
                <Route path="/user/add" element={<UserAddPage />} />
                <Route path="/user/detail" element={<UserDetailPage />} />
            </Route>

            <Route path="/rapor/" element={<ProtectedLayout />}>
                <Route path="/rapor/kasa-gunluk-rapor" element={<KasaGunlukRaporPage />} />
                <Route path="/rapor/kasa-detay-rapor" element={<KasaDetayRaporPage />} />
                <Route path="/rapor/kasa-aylik-dokum-rapor" element={<KasaAylikDokumRaporPage />} />

            </Route>

            <Route path="*" element={<ProtectedLayout></ProtectedLayout>}>
                <Route path="*" element={<DashboardPage />}></Route>
            </Route>
        </Route>
    )
)