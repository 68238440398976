import {Button, Card, Col, message, Popconfirm, Row, Space, Table, Tooltip} from "antd";
import {useEffect, useState} from "react";
import Api from "../../services/Api";
import Column from "antd/es/table/Column";
import {Link, useNavigate} from "react-router-dom";
import {DeleteOutlined, FormOutlined, PlusOutlined} from "@ant-design/icons";

const UsersListPage = () => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const navigate = useNavigate()

    const getData = () => {
        Api.get("/api/Users?PageIndex=0&PageSize=1000000").then(res => {
            setData(res.data.items)
            setLoading(false)
        }).catch(err => {
            message.error("Kullanıcılar yüklenemedi")
            setLoading(false)
        })
    }

    const getExtreMenu = () => {
        return (
            <Row gutter={5}>
                <Col>
                    <Tooltip title={"Yeni Ekle"}>
                        <Button title={"Ekle"} onClick={() => navigate("/user/add")}><PlusOutlined /></Button>
                    </Tooltip>
                </Col>

            </Row>
        )
    }

    const getDeleteData = (id) => {
        Api.delete("/api/user/", {data:{"id":id}}).then(res => {
            message.success("İşlem başarılı")
            getData()
        }).catch(err => message.error("Bir hata gerçekleşti"))
    }

    useEffect(() => {
        getData()
    }, []);

    return(
        <div className={"p-1"}>
            <Card loading={loading} title={"Kullanıcılar"} extra={getExtreMenu()}>
                <Table rowKey={"id"} size={"small"} dataSource={data} pagination={true}>
                    <Column title={""} width={70} dataIndex={""} render={(_, record) => {
                        return(
                            <Space>
                                <Tooltip title="Kullanıcı Düzenle">
                                    <Link to="/user/detail" state={{id:record.id}}>
                                        <Button icon={<FormOutlined />}  size={"small"}></Button>
                                    </Link>
                                </Tooltip>

                                <Popconfirm
                                    placement="top"
                                    title={"Silme Onayı"}
                                    description={"Silinmesini istiyor musunuz?"}
                                    onConfirm={() => getDeleteData(record.id)}
                                    okText="Evet"
                                    cancelText="Hayır"
                                >
                                    <Button size={"small"} icon={<DeleteOutlined />} ></Button>
                                </Popconfirm>
                            </Space>
                        )
                    }}></Column>
                    {/*<Column title={"Adı"} dataIndex={["userDetail", "firstName"]}></Column>*/}
                    {/*<Column title={"Soyadı"} dataIndex={["userDetail", "lastName"]}></Column>*/}
                    <Column title={"Adı"} dataIndex={"firstName"}></Column>
                    <Column title={"Soyadı"} dataIndex={"lastName"}></Column>
                    <Column title={"Eposta"} dataIndex={"email"}></Column>
                </Table>
            </Card>
        </div>
    )
}

export default UsersListPage;