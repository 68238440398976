import {Button, Card, Checkbox, Col, DatePicker, Form, Input, message, Row, Table} from "antd";
import {useEffect, useState} from "react";
import Api from "../../services/Api";
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import Column from "antd/es/table/Column";
import flexCurrency from "../../utils/FlexCurrency";

const KasaAylikDokumRaporPage = () => {
    const [data, setData] = useState([])
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)

    const onFinisFailed = () => {}

    const onFinish = (values) => {
        Api.get(`api/Report/GetBuyingReportWidthMonth?Year=${dayjs(values.year).format("YYYY")}&IsAll=`+values.isAll).then(res => {
            setData(res.data.buyingMonthReportList)
            console.log(values)
        }).catch(err => {
            message.error("Bir şeyler ters gitti. Lütfen tekrar deneyin.")
        })
    }

    return(<div className={"p-1"}>
        <Card title={"Kasa Aylık Döküm Raporu"} style={{marginBottom:5}} >
            <Form
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinisFailed}
                layout={"horizontal"}
                initialValues={{isAll: false}}
                labelAlign={"left"}
                labelCol={{
                    span: 15,
                }}
                colon={false}
            >
                <Row gutter={[10,10]}>
                    <Col span={6}>
                        <Form.Item
                            label={"Dönem Yılı"}  name={"year"} rules={[{ required: true },]}>
                            <DatePicker  class={'w-100'}  picker={"year"} format={"YYYY"}></DatePicker>
                        </Form.Item>

                        <Form.Item
                            label={"Satış Yapılmayan Aylarda Getirilsin mi?"} name={"isAll"} valuePropName="checked">
                            <Checkbox></Checkbox>
                        </Form.Item>
                    </Col>

                    <Col span={18}>
                        <Form.Item>
                            <Button type={"primary"}  htmlType="submit">Raporu Getir</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>

        <Row gutter={[10,5]}>
            {data.map((item, index) => {
                return(
                    <Col span={24} key={index}>
                        <Card size={"small"}>
                            <Row gutter={[10,10]}>
                                <Col span={8}>
                                    <p className={"font-weight-bold"}>{dayjs(item.date).format("YYYY MMMM").toUpperCase()}</p>
                                </Col>
                                <Col span={8} className={"d-flex"} style={{ justifyContent:"right"}}>
                                    <p><b>Toplam Geçiş Kişi :</b></p>
                                    <p>{item.count} Kişi</p>
                                </Col>
                                <Col span={8} className={"d-flex text-align-right"} style={{justifyContent:"right"}}>
                                    <p><b>Toplam Geçiş Tutar: </b></p>
                                    <p>{flexCurrency(item.totalPrice)} TL</p>
                                </Col>
                            </Row>

                            <Row gutter={[10,10]}>
                                <Col span={24} className={"p-1"}>
                                    <Card title={"Günlük Satış Detayları"} size={"small"}>
                                        <Table dataSource={item.items} size={"small"} pagination={false}>
                                            <Column width={120} title={"Tarih"} dataIndex={"date"}
                                                    render={(_,record) => {
                                                return dayjs(record.date).format("DD.MM.YYYY")
                                            }}></Column>
                                            <Column width={120} title={"Geçiş Yapan Kişi"} dataIndex={"count"} render={(_,record) => {
                                                return(<p>{record.count} Kişi</p>)
                                            }}></Column>
                                            <Column width={120} title={"Tutar"}  dataIndex={"totalPrice"} render={(_,record) => {
                                                return(<p>{flexCurrency(record.totalPrice)} TL</p>)
                                            }} ></Column>
                                        </Table>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                )})
            }
        </Row>
    </div>)
}

export default KasaAylikDokumRaporPage;