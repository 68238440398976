import {useContext, useEffect, useState} from "react";
import {SerialPortContext} from "../../../context/SerialPortContext";
import {Button, Col, Input, message, Row, Space} from "antd";

const CardWriteComponent = ({writeData, modalClose}) => {
    const {ports, connectToPort, disconnectPort, data, setData, sendSerialData} = useContext(SerialPortContext);
    const [inputData, setInputData] = useState("R;CN01N00N0021013");

    useEffect(() => {
        try {
            sendSerialData(writeData);
        } catch (error) {
            message.error("Veri gönderilemedi, kart okuyucu bağlantısını kontrol ediniz");
        }
    }, [writeData]);

    useEffect(() => {
        if (data?.includes(writeData)) {
            // setInputData(data);
            sendSerialData("0");
            setData(null);
            modalClose();
        }
    }, [data]);


    return (
        <div>
            <Row gutter={[10, 10]}>
                <Col span={24} >
                    <Input.TextArea readonly={true} value={data?.split('\n').reverse().join('\n')} rows={4} />
                </Col>
            </Row>

            {/*<Row>*/}
            {/*    <Col span={24}>*/}
            {/*        <Button onClick={() => modalClose()}>Tamam</Button>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
        </div>
    );
};

export default CardWriteComponent;