import axios from "axios";
import TokenService from "./TokenService";
import {message} from "antd";
import tokenService from "./TokenService";

export const API_URL =
    process.env.NODE_ENV === 'development'
        ? 'http://localhost:5278/'
        : 'https://tgysapi.bolsoft.com.tr/';

export const PUBLIC_URL =
    process.env.NODE_ENV === 'development'
        ? 'http://localhost:3000/'
        : 'https://tgys.bolsoft.com.tr/';


const instance = axios.create({
    baseURL: API_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

instance.interceptors.request.use(
    (config) => {
        const token = TokenService.getLocalAccessToken();

        if (token !== undefined && token != null) {
            config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {

        const originalConfig = err.config;

        if(originalConfig.url !== "/login" && !originalConfig._retry){
            if(err.response !== undefined && err.response.status === 401){
                if(err.response.data.type === "You are not authorized."){
                    message.error("Bu işlemi yapmak için yetkiniz yok")
                }
                else{
                    tokenService.removeUser()
                    // window.location.assign('/login')
                    console.log("--v_1")
                }
            }

            // if(err.response !== undefined && err.response.status === 500){
            //     if(err.response.data.detail === "You are not authorized."){
            //         message.error("Giriş yapmanız gerekmektedir")
            //         tokenService.removeUser();
            //         window.location.assign('/login')
            //     }
            // }

            if(err.response === undefined){
                originalConfig._retry = true;
                tokenService.removeUser();
                // window.location.assign('/login')
                return Promise.reject(err);
            }


            // if(err.response === undefined){
            //     originalConfig._retry = true;
            //     console.log("undufined geldi!")
            //     console.log("refreshToken Alınacak!!")
            //
            //     // console.log(err.response)
            //
            //     // const rs = await instance.get("/api/Auth/RefreshToken?refreshToken="+TokenService.getLocalRefreshToken(), {
            //     //     refreshToken: TokenService.getLocalRefreshToken(),
            //     // }).then(res =>
            //     //     {
            //     //         console.log(res)
            //     //         console.log("evet çekti refreshToken!!")
            //     //         alert("refresh token geldi ve başarılı oldu")
            //     //         TokenService.updateLocalAccessToken(res.data.token,"52")
            //     //         return instance(originalConfig);
            //     //     }
            //     // ).catch(err => {
            //     //     //refresh token çekerken hata oluştu o zaman tokenService user sil
            //     //     tokenService.removeUser()
            //     //     window.location.assign('/login')
            //     //     return Promise.reject(err);
            //     // });
            //
            //     // console.log("refresh token çekkiyo")
            //     // console.log(rs.data)
            //     // console.log(rs)
            // }
        }

        // if (originalConfig.url !== "/login" && err.response) {
        //     // Access Token was expired
        //     if (err.response.status === 401 && !originalConfig._retry) {
        //         originalConfig._retry = true;
        //         try {
        //             console.log("refreshToken:")
        //             console.log(TokenService.getLocalRefreshToken())
        //
        //             const rs = await instance.get("/api/Auth/RefreshToken?refreshToken="+TokenService.getLocalRefreshToken(), {
        //                 refreshToken: TokenService.getLocalRefreshToken(),
        //             });
        //
        //
        //             TokenService.setUser(rs.data.data)
        //             return instance(originalConfig);
        //         } catch (error) {
        //             // TokenService.removeUser()
        //             // window.location.assign('/')
        //             return Promise.reject(error);
        //         }
        //     }
        // }

        console.log(err)
        if(err.response){
            console.log(err)
            if(err.response.status === 401) {
                message.error("Bu işlemi yapmak için yetkiniz yok")

                //Burada bu işlemi yapmak için yetkiniz yok diyor ya oraya refresh token ekleyebiliriz!
            }
        }


        return Promise.reject(err);
    }
);

export default instance;
