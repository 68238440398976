import {Button, Card, message, Popconfirm, Space, Table, Tooltip} from "antd";
import Column from "antd/es/table/Column";
import {Link} from "react-router-dom";
import {CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, FormOutlined, PlusOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import Api from "../../services/Api";
import flexCurrency from "../../utils/FlexCurrency";

const RecipeListPage = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    const getData = () => {
        Api.get("api/Recipes?PageIndex=0&PageSize=100").then(res => {
            setData(res.data.items)
            setLoading(false)
        }).catch(err => {
            message.error("Bir hata oluştu")
            setLoading(false)
        })
    }

    useEffect(() => {
        getData()
    }, []);

    const getExtreMenu = () => {
        return (
            <Space>
                <Tooltip title={"Yeni Ekle"}>
                    <Link to={"/recipe/add"}>
                        <Button title={"Ekle"}><PlusOutlined /></Button>
                    </Link>
                </Tooltip>
            </Space>
        )
    }

    const getDeleteData = (id) => {
        setLoading(true)
        Api.delete("/api/Recipes/" + id).then(res => {
            message.success("Silme işlemi başarılı")
            getData()
        }).catch(err => {
            message.error("Silme işlemi başarısız oldu!");
            setLoading(false)
        })
    }

    return(
        <div className={"p-1"}>
            <Card loading={loading} title={"Tarifeler"} extra={getExtreMenu()}>
                <Table rowKey={"id"} size={"small"} dataSource={data} pagination={true}>
                    <Column title={""} width={70} dataIndex={""} render={(_, record) => {
                        return(
                            <Space>
                                <Tooltip title="Tarife Düzenle">
                                    <Link to="/recipe/detail" state={{id:record.id}}>
                                        <Button icon={<FormOutlined />}  size={"small"}></Button>
                                    </Link>
                                </Tooltip>

                                <Popconfirm
                                    placement="top"
                                    title={"Silme Onayı"}
                                    description={"Silinmesini istiyor musunuz?"}
                                    onConfirm={() => getDeleteData(record.id)}
                                    okText="Evet"
                                    cancelText="Hayır"
                                >
                                    <Button size={"small"} icon={<DeleteOutlined />} ></Button>
                                </Popconfirm>
                            </Space>
                        )
                    }}></Column>
                    <Column
                        title={"Tarife Durumu"}
                        width={120}
                        align={"center"}
                        dataIndex={"status"}
                        render={status => (
                            status ? <CheckCircleOutlined style={{color:"green"}} /> : <CloseCircleOutlined />
                        )}
                    />
                    <Column title={"Tarife Kodu"} align={"center"} width={120} dataIndex={"code"}></Column>
                    <Column title={"Tarife Adı"}  dataIndex={"name"}></Column>
                    <Column title={"Tarife Tutar"} dataIndex={"price"} render={(_,record) => {
                        return flexCurrency(record.price)
                    } }></Column>


                </Table>
            </Card>
        </div>
    )
}

export default RecipeListPage