import {Button, Checkbox, Form, Input, message} from "antd";
import {useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";
import Api, {PUBLIC_URL} from "../../services/Api";

const LoginPage = () => {
    const [loading, setLoading] = useState();
    const {login, user} = useContext(AuthContext);

    const onFinish = values => {
        setLoading(true)
        Api.post("/api/Auth/login", {email:values.username, password:values.password}).then(res => {
            if(res.data != null){
                setLoading(false)
                login(res.data)
            }
        }).catch(err => {
            message.error("Giriş Yapılamadı")
            setLoading(false)
        })
    }

    const onFinishFailed = errorInfo => {message.error("Bir şeyler ters gitti")}

    return (
        <div className="login-page">
            <div className="login-box">
                <div className="illustration-wrapper">
                    <img
                        src={`${PUBLIC_URL}/assets/images/rayta-park-slots-logo.jpg`}
                        alt="Login"
                    />
                </div>
                <Form
                    name="login-form"
                    initialValues={{remember: true}}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <p className="form-title">Hoş Geldiniz</p>
                    <p>Lütfen bilgileri doldurunuz</p>
                    <Form.Item
                        name="username"
                        rules={[{required: true, message: 'Lütfen e-posta adresinizi yazın'}]}
                    >
                        <Input
                            placeholder="E-posta adresiniz"
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        className={"mt-0-5"}
                        rules={[{required: true, message: 'Lütfen şifrenizi yazın'}]}
                    >
                        <Input.Password
                            placeholder="Şifreniz"
                        />
                    </Form.Item>

                    <Form.Item name="remember" valuePropName="checked">
                        <Checkbox>Beni hatırla</Checkbox>
                    </Form.Item>

                    <Form.Item>
                        <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
                            GİRİŞ YAPIN
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

export default LoginPage